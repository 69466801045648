import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Icon from './Icon';
import Logo from '../assets/images/logo.png';

const Box = styled.div`
  z-index: 1;
  position: relative;
`;

const LogoContainer = styled.div`
  padding: 0 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.85);
  border-radius: 0 0 4px 4px;
  ${({ isMenuOpen }) => isMenuOpen && `border-radius: 0;`}
  text-align: center;
  z-index: 1;
`;

const InnerBox = styled.div`
  max-width: var(--maxContentWidthPages);
  width: fit-content;
  margin: auto;
  padding: 0 var(--maxContentPadding);
`;

const InnerContentBox = styled.div`
  width: 100%;
  height: var(--navHeight);
  position: relative;
  display: flex;
  flex-direction: column;
`;

const MenuBox = styled.div`
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.85);
  border-radius: 0 0 4px 4px;
  pointer-events: ${({ isMenuOpen }) => (isMenuOpen ? 'all' : 'none')};
  padding: 20px;
  font-size: 1rem;
  flex-direction: column;
  gap: 9px;
  opacity: ${({ isMenuOpen }) => (isMenuOpen ? 1 : 0)};
  transform: scale(${({ isMenuOpen }) => (isMenuOpen ? '100%' : '0')});
  transform-origin: top left;
  transition: 100ms all ease-in-out;
`;

const ArrowIcon = styled(Icon)`
  width: 15px;
  opacity: 0;
`;

const MenuIcon = styled(Icon)`
  position: absolute;
  left: 20px;
  height: 100%;
`;

const MenuText = styled.span``;
const LogoImg = styled.img`
  padding: 6px;
`;

const MenuLink = styled(Link)`
  width: fit-content;
  font-weight: 600;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  &:hover {
    ${ArrowIcon} {
      opacity: 1;
      transition: opacity 0.1s ease-in;
    }
    ${MenuText} {
      box-shadow: 0 2px 0 var(--mainNeutral);
      transition: box-shadow 0.1s ease-in;
    }
  }
`;

export default function Nav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    function handleClickOutside(e) {
      if (!e.path?.includes(menuRef.current) && !e.composedPath?.().includes(menuRef.current)) {
        setIsMenuOpen(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <Box>
      <InnerBox>
        <InnerContentBox ref={menuRef}>
          <LogoContainer onClick={() => setIsMenuOpen(!isMenuOpen)} isMenuOpen={isMenuOpen}>
            <MenuIcon icon={isMenuOpen ? 'Close' : 'Menu'} />
            <LogoImg src={Logo} alt="Logo" />
          </LogoContainer>
          <MenuBox isMenuOpen={isMenuOpen}>
            <MenuLink to="/" onClick={closeMenu}>
              <MenuText>Home</MenuText>
              <ArrowIcon icon="ArrowShort" />
            </MenuLink>
            <MenuLink to="/docs/adsupport" onClick={closeMenu}>
              <MenuText>Supported Ad Formats</MenuText>
              <ArrowIcon icon="ArrowShort" />
            </MenuLink>
            <MenuLink to="/docs/media" onClick={closeMenu}>
              <MenuText>Media Requirements</MenuText>
              <ArrowIcon icon="ArrowShort" />
            </MenuLink>
            <MenuLink to="/docs/surveyintegration" onClick={closeMenu}>
              <MenuText>Survey Integration</MenuText>
              <ArrowIcon icon="ArrowShort" />
            </MenuLink>
            <MenuLink to="/docs/requirements" onClick={closeMenu}>
              <MenuText>System Requirements</MenuText>
              <ArrowIcon icon="ArrowShort" />
            </MenuLink>
          </MenuBox>
        </InnerContentBox>
      </InnerBox>
    </Box>
  );
}
