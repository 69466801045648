import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --mainColor: #00b4ff; /* light blue */
  	--mainNeutral: #131631; /* dark blue/black */
  	--lighterNeutral: #acaeb9; /* light gray */
  	--lightestNeutral: #f0f2f7; /* super light gray */

  	--borderMain: 1px solid var(--mainNeutral);

  	--maxContentWidthLanding: 1200px;
  	--maxContentWidthPages: 930px;
  	--maxContentPadding: 40px;

    --bannerHeight: 450px;
    --navHeight: 70px;
    --footerHeight: 50px;
    --mediaWidth: 13rem;

    @media (max-width: 600px) {
  	  --maxContentPadding: 30px;
    }
    @media (max-width: 400px) {
  	  --maxContentPadding: 20px;
    }
  }

  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    overflow-x: hidden;
  }

  .mainCenteredContent {
    margin: auto;
    max-width: var(--maxContentWidthLanding);
    padding: 0 var(--maxContentPadding);
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: middle;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`;

export default GlobalStyles;
