import React from 'react';
import styled from 'styled-components';
import ArrowLong from '../assets/images/arrow-long.svg';
import ArrowShort from '../assets/images/arrow-short.inline.svg';
import Close from '../assets/images/close.inline.svg';
import ChevronDown from '../assets/images/chevron-down.inline.svg';
import Menu from '../assets/images/menu.inline.svg';
import QRCode from '../assets/images/qr-code.inline.svg';

const icons = {
  ArrowLong,
  ArrowShort,
  Close,
  ChevronDown,
  Menu,
  QRCode,
};

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
`;

export default function Icon({ icon, className }) {
  if (!icons[icon]) {
    throw new Error(`icon ${icon} doesn't exist`);
  }
  const IconComponent = icons[icon];
  return (
    <Box className={className}>
      <IconComponent />
    </Box>
  );
}
