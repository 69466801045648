import styled, { createGlobalStyle, css } from 'styled-components';

export const headerStyles = {
  h1: css`
    font-size: 2rem;
    margin-bottom: 1.3rem;
  `,
  h2: css`
    font-size: 1.7rem;
    margin-bottom: 1.2rem;
  `,
  h3: css`
    font-size: 1.4rem;
    margin-bottom: 1.1rem;
  `,
  h4: css`
    font-size: 1.1rem;
    margin-bottom: 1rem;
  `,
};

const Typography = createGlobalStyle`
  :root {
    --fontWeight: 200;
  }

  /* android */
  @supports not (-webkit-touch-callout: none) {
    @media (max-width: 800px) {
      :root {
        --fontWeight: 300;
      }
    }
  }

  html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 1.2rem;
  	font-weight: var(--fontWeight);
  	line-height: 1.3;
  	color: var(--mainNeutral);
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 600;
  }

  a {
    color: var(--mainNeutral);
    text-decoration: none;
    font-weight: 600;
  }

  strong {
    font-weight: 600;
  }

  ::selection {
    background-color: var(--mainColor);
    color: white;
  }
`;

export const H1Header = styled.h1`
  ${headerStyles.h1}
`;

export const H2Header = styled.h2`
  ${headerStyles.h2}
`;

export const H3Header = styled.h3`
  ${headerStyles.h3}
`;

export const H4Header = styled.h4`
  ${headerStyles.h4}
`;

export const LandingTitle = styled.h1`
  font-size: 3rem;
  font-weight: 100;
  text-shadow: 0 0px 10px rgba(255, 255, 255, 0.4);
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
  text-align: center;
`;

export const LandingSinglePageTitle = styled(H2Header)`
  margin-bottom: 1rem;
`;

export const DemoPageLinkTitle = styled(H4Header)`
  margin: 15px;
  margin-left: 0;
  margin-right: 20px;
`;

export default Typography;
