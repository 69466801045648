import React from 'react';
import styled from 'styled-components';
import Nav from './Nav';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

const MainContainer = styled.div`
  position: relative;
  min-height: 100vh;
  padding-bottom: var(--footerHeight);
`;

export default function Layout(props) {
  return (
    <MainContainer>
      <GlobalStyles />
      <Typography />
      <Nav path={props.path} />
      {props.children}
    </MainContainer>
  );
}
